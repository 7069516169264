<template>
  <div>
    <CModal
        color="white"
        :size="Msize"
        :closeOnBackdrop="false"
        centered
        :show.sync="MisActive"
    >
      <template #footer>
        <CButton size="lg" @click="isActive = false" color="info">Chiudi</CButton>
        <CButton size="lg" @click="isActive = false" color="info">Ok</CButton>
      </template>
      <br>
      <h4 style="text-align:center;">{{ Mtext }}</h4>
      <br>

      <div class="custom-error-text">
        <div class="listaErrori" v-for="(n, index) in MerrorText">
          <h5>{{ index }} : {{ n }}</h5>
        </div>
      </div>

    </CModal>
  </div>
</template>

<script>

export default {
  data() {
    return {
      Mtext: this.text,
      Msize: this.size,
      MisActive: this.isActive,
      MerrorText: this.errorText
    }
  },
  props: ['text', 'size', 'isActive', 'errorText'],
  name: "Modal"

}
</script>

