<template>
  <div>

    <CRow>
      <CCol sm="12">
        <CCard>
          <CCardHeader>
            <CButton
                @click="create"
                type="submit"
                color="info"
            >
              Aggiungi Sede
            </CButton>
          </CCardHeader>

          <CCardBody v-if="sedi !== null && sedi.length > 0">
            <CCardHeader>
              <div v-for="sede in sedi" :key="sede.id">
                <CRow>
                  <CCol sm="12">
                    <CCard class="customCard">
                      <CCardBody>

                        <CInput
                            label="Indirizzo (*)"
                            class="required-field-indirizzo"
                            v-model="sede.address"
                        />
                        <CRow>
                          <CCol sm="6">
                            <SingleComune
                                v-bind:selected="sede.comuni"
                                v-on:getComune="(comune)=>{sede.comuni= comune}"
                                class="required-field-indirizzo"
                                title="Comune (*)"
                            />
                          </CCol>
                          <CCol sm="6">
                            <CInput
                                label="Telefono"
                                v-model="sede.telefono"
                            />
                          </CCol>


                          <CCol sm="12">
                            <CButton v-if="sede.comuni !== null && sede.address !== null"
                                     @click="update(sede)"
                                     type="submit"
                                     color="primary"
                            >
                              <CIcon name="cil-save"/>
                              Salva dati Sede
                            </CButton>

                            <CButton
                                @click="destroy(sede)"
                                type="submit"
                                color="danger"
                                class="save-sedi"
                            >
                              <CIcon name="cil-trash"/>
                              Rimuovi dati Sede
                            </CButton>
                          </CCol>

                        </CRow>

                      </CCardBody>
                    </CCard>
                  </CCol>
                </CRow>

              </div>
            </CCardHeader>
          </CCardBody>
        </CCard>

      </CCol>
    </CRow>

  </div>
</template>

<script>
import SingleComune from "@/views/funer24/components/SingleComune";
import Modal from "@/views/funer24/components/Modal";
import CellulareSedi from "@/views/funer24/components/website/CellulareSedi";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "Sede",
  components: {
    SingleComune,
    Modal,
    CellulareSedi,
  },

  data: function () {
    return {
      title: 'Sedi',
    };
  },
  computed: {
    ...mapGetters('sedi', [
      'sedi',
    ]),
  },

  methods: {

    ...mapActions('sedi',
        {
          list: 'list',
          destroy: 'destroy',
          create: 'create',
          update: 'update',
        }),

    ...mapActions('cellularisedi',
        {
          listCellulariSede: 'list',
        }),
  }
}
</script>

<style>

.required-field-indirizzo.form-group > label {
  font-weight: 700;
}

.remove-cellulari-azienda {
  margin-left: 12px;
}

.add-cellulari-sede {
  margin-right: 12px;
}

.save-cellulari {
  margin-left: 12px;
}

.salve-cellulari-sedi {
  margin-left: 12px;
}

.save-sedi {
  margin-left: 12px;
}
</style>
