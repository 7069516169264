<template>
  <div>
    <Modal
        :isActive="isActive"
        :text="text"
        :size="size"
        :errorText="errors"
    ></Modal>

    <CRow>
      <CCol sm="12">
        <CCardHeader>
          <strong>Contatti</strong>
        </CCardHeader>
        <CCard class="customCard">
          <CCardBody>

            <CRow>
              <CCol sm="6">
                <CInput
                    label="Email"
                    v-model="agenzia.email"
                />
              </CCol>
              <CCol sm="6">
                <CInput
                    label="Telefono"
                    v-model="agenzia.telefono"
                />
              </CCol>
            </CRow>

            <CRow>
              <CCol sm="6">
                <CInput
                    label="Partita iva"
                    v-model="agenzia.partita_iva"
                />
              </CCol>
              <CCol sm="6">
                <CInput
                    label="Pagina Facebook"
                    v-model="agenzia.facebook"
                />
              </CCol>
            </CRow>

            <CRow>
              <CCol sm="6">
                <SingleComune
                    v-bind:selected="agenzia.comuni"
                    v-on:getComune="(comune)=>{ agenzia.comuni = comune}"
                    title="Comune (*)"
                />
              </CCol>
              <CCol sm="6">
                <CInput
                    label="Indirizzo"
                    v-model="agenzia.address"
                />
              </CCol>
            </CRow>

            <CButton
                v-on:click="update(agenzia)"
                class="custom-save-data-agency"
                type="submit"
                color="info"
            >
              Salva Dati Agenzia
            </CButton>

          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <Cellulare></Cellulare>
    <Sede></Sede>
    <!-- <CellulareSedi></CellulareSedi> -->

  </div>
</template>

<script>

import CellulareSedi from "@/views/funer24/components/website/CellulareSedi";
import Sede from "@/views/funer24/components/website/Sede";
import Cellulare from "@/views/funer24/components/website/Cellulare";
import UploadMultiplePhotoAndPreview from "@/views/funer24/components/UploadMultiplePhotoAndPreview";
import Modal from "@/views/funer24/components/Modal";
import GoogleMapsAutocomplete from "@/views/funer24/components/GoogleMapsAutocomplete";
import SingleComune from "@/views/funer24/components/SingleComune";
import {VueEditor} from "vue2-editor";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "Contatti",
  components: {
    UploadMultiplePhotoAndPreview,
    VueEditor,
    Cellulare,
    Sede,
    SingleComune,
    GoogleMapsAutocomplete,
    CellulareSedi,
    Modal,
  },
  data: function () {
    return {
      title: 'Contatti',
      isActive: false,
      size: 'xl',
      text: '',
      errors: [],
    };
  },
  computed: {

    ...mapGetters('agenzia', [
      'agenzia',
    ]),
    ...mapGetters('cellulari', [
      'cellulari',
    ]),

  },
  methods: {

    ...mapActions('agenzia',
        {
          updateAgenzia: 'update',
        }),

    ...mapActions('cellulari',
        {
          listCellulari: 'list',
        }),

    ...mapActions('sedi',
        {
          listSedi: 'list',
        }),

    update(item) {

      let comuni = null;
      if (item.comuni !== null) {
        if (item.comuni.ref_id !== undefined) {
          comuni = parseInt(item.comuni.ref_id);
        } else {
          comuni = parseInt(item.comuni.id);
        }
      }

      let obj = {
        email: item.email,
        telefono: item.telefono,
        comuni: comuni,
        address: item.address,
        partita_iva: item.partita_iva,
        facebook: item.facebook,
        sito_web: item.sito_web,
      }

      this
          .updateAgenzia(obj)
          .then((res) => {
            console.log(res)
            if (res) this.$router.push('/dashboard')
          })
          .catch((e) => {
            console.log(e)
          })
    },

  },
  created() {
    this.listCellulari();
    this.listSedi();
  }
}
</script>

<style>
.remove-cellulari-azienda {
  margin-left: 12px;
}

.add-cellulari-sede {
  margin-right: 12px;
}

.save-cellulari {
  margin-left: 12px;
}

.salve-cellulari-sedi {
  margin-left: 12px;
}

.save-sedi {
  margin-left: 12px;
}
</style>
